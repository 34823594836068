import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LightboxEvent, LightboxImage } from '@phothor/shared/lightbox/lightboxImage';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LightboxService {
  lightboxEventSubject: Subject<LightboxEvent> = new Subject<LightboxEvent>();
  lightboxEvent$ = this.lightboxEventSubject.asObservable();

  constructor(private http: HttpClient) {}

  openLightbox(items: LightboxImage[], itemId: number = 0) {
    this.lightboxEventSubject.next({
      photos: items,
      selectedId: itemId,
    });
  }
}
